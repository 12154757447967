import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41a224c7 = () => interopDefault(import('../pages/ac.vue' /* webpackChunkName: "pages/ac" */))
const _61fcd317 = () => interopDefault(import('../pages/ac/_.vue' /* webpackChunkName: "pages/ac/_" */))
const _38c12049 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _5646e7e8 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/index.vue' /* webpackChunkName: "pages/checkout/index/index" */))
const _5db9565e = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/auth.vue' /* webpackChunkName: "pages/checkout/index/auth" */))
const _52a10c20 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/step2.vue' /* webpackChunkName: "pages/checkout/index/step2" */))
const _52af23a1 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/index/step3.vue' /* webpackChunkName: "pages/checkout/index/step3" */))
const _be69c67a = () => interopDefault(import('../pages/customer.vue' /* webpackChunkName: "pages/customer" */))
const _2a31c506 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _888b76b6 = () => interopDefault(import('../pages/customer/agreements.vue' /* webpackChunkName: "pages/customer/agreements" */))
const _7a89fc12 = () => interopDefault(import('../pages/customer/beneficiaries.vue' /* webpackChunkName: "pages/customer/beneficiaries" */))
const _12dfbf8c = () => interopDefault(import('../pages/customer/card.vue' /* webpackChunkName: "pages/customer/card" */))
const _72daa586 = () => interopDefault(import('../pages/customer/edit.vue' /* webpackChunkName: "pages/customer/edit" */))
const _4fbbb4b8 = () => interopDefault(import('../pages/customer/orders/index.vue' /* webpackChunkName: "pages/customer/orders/index" */))
const _5b71f568 = () => interopDefault(import('../pages/customer/questionnaire/mental-health.vue' /* webpackChunkName: "pages/customer/questionnaire/mental-health" */))
const _25118008 = () => interopDefault(import('../pages/customer/questionnaire/participants.vue' /* webpackChunkName: "pages/customer/questionnaire/participants" */))
const _96f57de8 = () => interopDefault(import('../pages/customer/orders/_id.vue' /* webpackChunkName: "pages/customer/orders/_id" */))
const _0f713786 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _d010e25c = () => interopDefault(import('../pages/invoice-data-form.vue' /* webpackChunkName: "pages/invoice-data-form" */))
const _11ac8690 = () => interopDefault(import('../pages/mediclub.vue' /* webpackChunkName: "pages/mediclub" */))
const _43b78357 = () => interopDefault(import('../pages/modal-test.vue' /* webpackChunkName: "pages/modal-test" */))
const _44fa3a46 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _54374745 = () => interopDefault(import('../pages/rate.vue' /* webpackChunkName: "pages/rate" */))
const _31108216 = () => interopDefault(import('../pages/complaint.vue' /* webpackChunkName: "pages/complaint" */))
const _d69cae22 = () => interopDefault(import('../pages/terms-form.vue' /* webpackChunkName: "pages/terms-form" */))
const _de7b80b2 = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))
const _15b3ad09 = () => interopDefault(import('../pages/update-beneficiary/index.vue' /* webpackChunkName: "pages/update-beneficiary/index" */))
const _c9564924 = () => interopDefault(import('../pages/returns.vue' /* webpackChunkName: "pages/returns" */))
const _6a185fe7 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _2604a57c = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _272753a0 = () => interopDefault(import('../pages/auth/password-change.vue' /* webpackChunkName: "pages/auth/password-change" */))
const _6526a00f = () => interopDefault(import('../pages/auth/password-reset.vue' /* webpackChunkName: "pages/auth/password-reset" */))
const _28d15b95 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _26137558 = () => interopDefault(import('../pages/c/null.vue' /* webpackChunkName: "pages/c/null" */))
const _2ec31064 = () => interopDefault(import('../node_modules/@strix/checkout/pages/checkout/EmptyCart.vue' /* webpackChunkName: "pages/checkout/EmptyCart" */))
const _e463982a = () => interopDefault(import('../pages/p/null.vue' /* webpackChunkName: "pages/p/null" */))
const _bf6cb3b0 = () => interopDefault(import('../node_modules/@strix/search/pages/search/q.vue' /* webpackChunkName: "pages/search/q" */))
const _1a054273 = () => interopDefault(import('../pages/update-beneficiary/thank-you.vue' /* webpackChunkName: "pages/update-beneficiary/thank-you" */))
const _82dcd954 = () => interopDefault(import('../pages/p/_slug.vue' /* webpackChunkName: "pages/p/_slug" */))
const _e6bf151e = () => interopDefault(import('~/pages/p/_slug.vue' /* webpackChunkName: "" */))
const _c70f8248 = () => interopDefault(import('../pages/a/_.vue' /* webpackChunkName: "pages/a/_" */))
const _0644b55e = () => interopDefault(import('../pages/c/_.vue' /* webpackChunkName: "pages/c/_" */))
const _65038eca = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ac",
    component: _41a224c7,
    pathToRegexpOptions: {"strict":true},
    name: "ac___pl",
    children: [{
      path: "*",
      component: _61fcd317,
      pathToRegexpOptions: {"strict":true},
      name: "ac-all___pl"
    }]
  }, {
    path: "/checkout",
    component: _38c12049,
    children: [{
      path: "",
      component: _5646e7e8,
      name: "checkout-index___pl"
    }, {
      path: "auth",
      component: _5db9565e,
      name: "checkout-index-auth___pl"
    }, {
      path: "step2",
      component: _52a10c20,
      name: "checkout-index-step2___pl"
    }, {
      path: "step3",
      component: _52af23a1,
      name: "checkout-index-step3___pl"
    }]
  }, {
    path: "/customer",
    component: _be69c67a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _2a31c506,
      pathToRegexpOptions: {"strict":true},
      name: "customer___pl"
    }, {
      path: "agreements",
      component: _888b76b6,
      pathToRegexpOptions: {"strict":true},
      name: "customer-agreements___pl"
    }, {
      path: "beneficiaries",
      component: _7a89fc12,
      pathToRegexpOptions: {"strict":true},
      name: "customer-beneficiaries___pl"
    }, {
      path: "card",
      component: _12dfbf8c,
      pathToRegexpOptions: {"strict":true},
      name: "customer-card___pl"
    }, {
      path: "edit",
      component: _72daa586,
      pathToRegexpOptions: {"strict":true},
      name: "customer-edit___pl"
    }, {
      path: "orders",
      component: _4fbbb4b8,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders___pl"
    }, {
      path: "questionnaire/mental-health",
      component: _5b71f568,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-mental-health___pl"
    }, {
      path: "questionnaire/participants",
      component: _25118008,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-participants___pl"
    }, {
      path: "orders/:id",
      component: _96f57de8,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders-id___pl"
    }]
  }, {
    path: "/en",
    component: _0f713786,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/formularz-danych-do-faktury",
    component: _d010e25c,
    pathToRegexpOptions: {"strict":true},
    name: "invoice-data-form___pl"
  }, {
    path: "/mediclub",
    component: _11ac8690,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub___pl"
  }, {
    path: "/modal-test",
    component: _43b78357,
    pathToRegexpOptions: {"strict":true},
    name: "modal-test___pl"
  }, {
    path: "/opinie",
    component: _44fa3a46,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___pl"
  }, {
    path: "/rate",
    component: _54374745,
    pathToRegexpOptions: {"strict":true},
    name: "rate___pl"
  }, {
    path: "/reklamacja",
    component: _31108216,
    pathToRegexpOptions: {"strict":true},
    name: "complaint___pl"
  }, {
    path: "/terms-form",
    component: _d69cae22,
    pathToRegexpOptions: {"strict":true},
    name: "terms-form___pl"
  }, {
    path: "/thank-you",
    component: _de7b80b2,
    pathToRegexpOptions: {"strict":true},
    name: "thank-you___pl"
  }, {
    path: "/update-beneficiary",
    component: _15b3ad09,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary___pl"
  }, {
    path: "/zwroty",
    component: _c9564924,
    pathToRegexpOptions: {"strict":true},
    name: "returns___pl"
  }, {
    path: "/auth/login",
    component: _6a185fe7,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___pl"
  }, {
    path: "/auth/logout",
    component: _2604a57c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___pl"
  }, {
    path: "/auth/password-change",
    component: _272753a0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-change___pl"
  }, {
    path: "/auth/password-reset",
    component: _6526a00f,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___pl"
  }, {
    path: "/auth/register",
    component: _28d15b95,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___pl"
  }, {
    path: "/c/null",
    component: _26137558,
    pathToRegexpOptions: {"strict":true},
    name: "c-null___pl"
  }, {
    path: "/checkout/EmptyCart",
    component: _2ec31064,
    name: "checkout-EmptyCart___pl"
  }, {
    path: "/en/ac",
    component: _41a224c7,
    pathToRegexpOptions: {"strict":true},
    name: "ac___en",
    children: [{
      path: "*",
      component: _61fcd317,
      pathToRegexpOptions: {"strict":true},
      name: "ac-all___en"
    }]
  }, {
    path: "/en/checkout",
    component: _38c12049,
    children: [{
      path: "",
      component: _5646e7e8,
      name: "checkout-index___en"
    }, {
      path: "auth",
      component: _5db9565e,
      name: "checkout-index-auth___en"
    }, {
      path: "step2",
      component: _52a10c20,
      name: "checkout-index-step2___en"
    }, {
      path: "step3",
      component: _52af23a1,
      name: "checkout-index-step3___en"
    }]
  }, {
    path: "/en/complaints",
    component: _31108216,
    pathToRegexpOptions: {"strict":true},
    name: "complaint___en"
  }, {
    path: "/en/customer",
    component: _be69c67a,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _2a31c506,
      pathToRegexpOptions: {"strict":true},
      name: "customer___en"
    }, {
      path: "agreements",
      component: _888b76b6,
      pathToRegexpOptions: {"strict":true},
      name: "customer-agreements___en"
    }, {
      path: "beneficiaries",
      component: _7a89fc12,
      pathToRegexpOptions: {"strict":true},
      name: "customer-beneficiaries___en"
    }, {
      path: "card",
      component: _12dfbf8c,
      pathToRegexpOptions: {"strict":true},
      name: "customer-card___en"
    }, {
      path: "edit",
      component: _72daa586,
      pathToRegexpOptions: {"strict":true},
      name: "customer-edit___en"
    }, {
      path: "orders",
      component: _4fbbb4b8,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders___en"
    }, {
      path: "questionnaire/mental-health",
      component: _5b71f568,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-mental-health___en"
    }, {
      path: "questionnaire/participants",
      component: _25118008,
      pathToRegexpOptions: {"strict":true},
      name: "customer-questionnaire-participants___en"
    }, {
      path: "orders/:id",
      component: _96f57de8,
      pathToRegexpOptions: {"strict":true},
      name: "customer-orders-id___en"
    }]
  }, {
    path: "/en/invoice-data-form",
    component: _d010e25c,
    pathToRegexpOptions: {"strict":true},
    name: "invoice-data-form___en"
  }, {
    path: "/en/mediclub",
    component: _11ac8690,
    pathToRegexpOptions: {"strict":true},
    name: "mediclub___en"
  }, {
    path: "/en/modal-test",
    component: _43b78357,
    pathToRegexpOptions: {"strict":true},
    name: "modal-test___en"
  }, {
    path: "/en/rate",
    component: _54374745,
    pathToRegexpOptions: {"strict":true},
    name: "rate___en"
  }, {
    path: "/en/returns",
    component: _c9564924,
    pathToRegexpOptions: {"strict":true},
    name: "returns___en"
  }, {
    path: "/en/reviews",
    component: _44fa3a46,
    pathToRegexpOptions: {"strict":true},
    name: "reviews___en"
  }, {
    path: "/en/terms-form",
    component: _d69cae22,
    pathToRegexpOptions: {"strict":true},
    name: "terms-form___en"
  }, {
    path: "/en/thank-you",
    component: _de7b80b2,
    pathToRegexpOptions: {"strict":true},
    name: "thank-you___en"
  }, {
    path: "/en/update-beneficiary",
    component: _15b3ad09,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary___en"
  }, {
    path: "/p/null",
    component: _e463982a,
    pathToRegexpOptions: {"strict":true},
    name: "p-null___pl"
  }, {
    path: "/search/q",
    component: _bf6cb3b0,
    name: "search-q___pl"
  }, {
    path: "/update-beneficiary/thank-you",
    component: _1a054273,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary-thank-you___pl"
  }, {
    path: "/en/auth/login",
    component: _6a185fe7,
    pathToRegexpOptions: {"strict":true},
    name: "auth-login___en"
  }, {
    path: "/en/auth/logout",
    component: _2604a57c,
    pathToRegexpOptions: {"strict":true},
    name: "auth-logout___en"
  }, {
    path: "/en/auth/password-change",
    component: _272753a0,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-change___en"
  }, {
    path: "/en/auth/password-reset",
    component: _6526a00f,
    pathToRegexpOptions: {"strict":true},
    name: "auth-password-reset___en"
  }, {
    path: "/en/auth/register",
    component: _28d15b95,
    pathToRegexpOptions: {"strict":true},
    name: "auth-register___en"
  }, {
    path: "/en/c/null",
    component: _26137558,
    pathToRegexpOptions: {"strict":true},
    name: "c-null___en"
  }, {
    path: "/en/checkout/EmptyCart",
    component: _2ec31064,
    name: "checkout-EmptyCart___en"
  }, {
    path: "/en/p/null",
    component: _e463982a,
    pathToRegexpOptions: {"strict":true},
    name: "p-null___en"
  }, {
    path: "/en/search/q",
    component: _bf6cb3b0,
    name: "search-q___en"
  }, {
    path: "/en/update-beneficiary/thank-you",
    component: _1a054273,
    pathToRegexpOptions: {"strict":true},
    name: "update-beneficiary-thank-you___en"
  }, {
    path: "/en/p/:slug?",
    component: _82dcd954,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___en"
  }, {
    path: "/en/p/:slug/:virtual",
    component: _e6bf151e,
    name: "p-slug-virtual___en"
  }, {
    path: "/en/a/*",
    component: _c70f8248,
    pathToRegexpOptions: {"strict":true},
    name: "a-all___en"
  }, {
    path: "/en/c/*",
    component: _0644b55e,
    pathToRegexpOptions: {"strict":true},
    name: "c-all___en"
  }, {
    path: "/p/:slug?",
    component: _82dcd954,
    pathToRegexpOptions: {"strict":true},
    name: "p-slug___pl"
  }, {
    path: "/p/:slug/:virtual",
    component: _e6bf151e,
    name: "p-slug-virtual___pl"
  }, {
    path: "/en/*",
    component: _65038eca,
    name: "all1___en"
  }, {
    path: "/en/*",
    component: _65038eca,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/a/*",
    component: _c70f8248,
    pathToRegexpOptions: {"strict":true},
    name: "a-all___pl"
  }, {
    path: "/c/*",
    component: _0644b55e,
    pathToRegexpOptions: {"strict":true},
    name: "c-all___pl"
  }, {
    path: "/",
    component: _0f713786,
    pathToRegexpOptions: {"strict":true},
    name: "index___pl"
  }, {
    path: "/*",
    component: _65038eca,
    name: "all1___pl"
  }, {
    path: "/*",
    component: _65038eca,
    pathToRegexpOptions: {"strict":true},
    name: "all___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
